<template>
  <div class="advantage">
    <Headline :title="title" subtitle="Technology Advantages" />
    <div class="advantage-list">
      <div
        class="advantage-item"
        v-for="(item, index) in advantage"
        :key="index"
      >
        <img :src="item.imgUrl" alt="" />
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Headline from "@/components/headline.vue";
export default {
  components: { Headline },
  props: {
    title: { required: true },
    advantage: {
      type: Array,
      default() {
        return [
          { name: " 智能物联", imgUrl: require("@/assets/images/5.png") },
          { name: " 海量大数据", imgUrl: require("@/assets/images/6.png") },
          { name: " 智慧云服务", imgUrl: require("@/assets/images/7.png") },
          { name: " 行业解决方案", imgUrl: require("@/assets/images/8.png") }
        ];
      }
    }
  }
};
</script>
<style scoped lang="scss">
.advantage {
  .title {
    font-size: 50px;
    color: #333333;
    line-height: 70px;
  }
  .advantage-list {
    display: flex;
    justify-content: space-around;
    .advantage-item {
      margin-top: 60px;
      flex: 1;
      p {
        margin-top: 55px;
        font-size: 28px;
        line-height: 40px;
        color: #333;
      }
    }
  }
}
</style>
