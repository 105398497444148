<template>
  <div class="partner">
    <Headline title="合作伙伴" subtitle="Partners" />
    <div class="partner-list">
      <div
        class="partner-item"
        v-for="(item, index) in partnerList"
        :key="index"
        :style="{ backgroundImage: `url(${item.imgUrl})` }"
        :title="item.name"
      ></div>
    </div>
  </div>
</template>
<script>
import Headline from "@/components/headline.vue";
export default {
  components: { Headline },
  props: { partner: { type: Array } },
  computed: {
    partnerList() {
      let data = [
        { name: "RANCHER", imgUrl: require("@/assets/images/RANCHER.png") },
        { name: "阿里云", imgUrl: require("@/assets/images/aly.png") },
        {
          name: "北京涛思数据科技有限公司",
          imgUrl: require("@/assets/images/bjtssjkjyxgs.png")
        },
        { name: "迪高圈", imgUrl: require("@/assets/images/dgq.png") },
        { name: "歌华TV", imgUrl: require("@/assets/images/ghTV.png") },
        {
          name: "广东华城工程咨询有限公司惠州分公司",
          imgUrl: require("@/assets/images/gdhcgczxyxgshzfgs.png")
        },
        {
          name: "广州市星翼电子科技有限公司",
          imgUrl: require("@/assets/images/gzsxydzkjyxgs.png")
        },
        {
          name: "广州长奋科技有限公司",
          imgUrl: require("@/assets/images/gzcfkjyxgs.png")
        },
        { name: "软通动力", imgUrl: require("@/assets/images/rtdl.png") },
        {
          name: "深圳市千代科技有限公司",
          imgUrl: require("@/assets/images/szsqdkjyxgs.png")
        },
        { name: "时度效", imgUrl: require("@/assets/images/sdx.png") },
        { name: "腾讯教育", imgUrl: require("@/assets/images/txjy.jpg") },
        { name: "腾讯云", imgUrl: require("@/assets/images/txy.png") },
        {
          name: "野村综合研究所",
          imgUrl: require("@/assets/images/yczhyjs.png")
        }
      ];
      if (!this.partner) return data;
      else return data.filter(item => this.partner.includes(item.name));
    }
  }
};
</script>
<style scoped lang="scss">
.partner {
  .title {
    font-size: 50px;
    color: #333333;
    line-height: 70px;
  }

  .partner-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .partner-item {
      margin-top: 20px;
      border: 1px solid #e7e7e7;
      width: 23%;
      max-width: 275px;
      height: 160px;
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
    }
  }
}
</style>
