// 首页
<template>
  <div class="home">
    <div class="banner">
      <el-carousel class="banner-carousel" :interval="4000">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <template>
            <Bigimg
              :title="item.title"
              :subTitle="item.subTitle"
              :imgUrl="item.imgUrl"
            ></Bigimg>
          </template>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="news-wrapper">
      <div class="news wrap-v1">
        <Headline title="新闻动态" subtitle="News" />
        <div class="news-list-wrapper" v-if="showList.length">
          <div class="big-img">
            <img
              @click="goDetails(newsList[0])"
              :src="newsList[0].poster"
              alt=""
            />
          </div>
          <div class="news-list">
            <div
              :class="['news-item', index === 0 ? '' : 'mt-7']"
              v-for="(news, index) in showList"
              :key="index"
            >
              <div class="time">
                <div class="day">{{ news.addTime | getDay }}</div>
                <div class="month">{{ news.addTime | getMonth }}</div>
              </div>
              <div class="news-desc">
                <div class="news-title" @click="goDetails(news)">
                  {{ news.title }}
                </div>
                <div class="news-introduction text-nowrap3">
                  {{ news.introduction }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="service-wrapper">
      <div class="service wrap-v1">
        <Headline title="我们的业务" subtitle="Our Business" />
        <div class="service-list">
          <div class="service-item">
            <div class="top">
              <img src="../assets/images/2.png" />
              <p>智能物联网业务</p>
            </div>
            <div class="bottom">
              物联网是物物相通，而智能物联（Intelligent Interconnection Of
              Things，IIOT
              ）则是指除了物物相通外，智能物联不仅仅是网络、应用与管理，而且是面向业务的智能化的应用与服务，它的特殊之处在于，除了物物相通之外，还具有智慧，感知、交互、智慧是它的三大特点。
            </div>
          </div>
          <div class="service-item">
            <div class="top">
              <img src="../assets/images/3.png" />
              <p>智慧容器云业务</p>
            </div>
            <div class="bottom">
              容器云，由于其基于LXC的轻量级虚拟化的特点，相比KVM之类最明显的特点就是启动快，资源占用小。因此对于构建隔离的标准化的运行环境，轻量级的PaaS(如dokku),
              构建自动化测试和持续集成环境，以及一切可以横向扩展的应用(尤其是需要快速启停来应对峰谷的web应用)。
            </div>
          </div>
          <div class="service-item">
            <div class="top">
              <img src="../assets/images/4.png" />
              <p>行业应用业务</p>
            </div>
            <div class="bottom">
              行业应用是保障业务领先、技术领先、服务领先、市场领先的重要保证，其目的在于通过行业应用的建设、开发、示范、培训、交流等作用尽快在全省范围内形成竞争规模优势，促进行业应用的持续稳定、健康发展，为面向未来的业务的发展和市场开拓提供有力的借签。
            </div>
          </div>
          <div class="service-item">
            <div class="top">
              <img src="../assets/images/1.png" />
              <p>数据可视化业务</p>
            </div>
            <div class="bottom">
              数据可视化是基于人类的视觉认知系统的，对于人类视觉系统的工作方式有一些了解可以帮助我们设计出更为高效（更快的传递我们想要表达的信息给读者）的可视化作品，它使得信息对分析师和高层更具可读性和可展示性，可以呈现并与整个工作人员共享内容，即使是那些无法理解原始数据形式的人。
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage-wrapper">
      <Advantage class="wrap-v1" title="核心技术优势"></Advantage>
    </div>
    <div class="brand-wrapper">
      <div class="brand wrap-v2">
        <Headline title="品牌理念" subtitle="Brand Concept" />
        <div class="brand-list">
          <div class="brand-item">
            <div class="img-wrapper">
              <img src="../assets/images/13.png" alt="" />
            </div>
            <span>创新</span>
            <p></p>
          </div>
          <div class="brand-item">
            <div class="img-wrapper">
              <img src="../assets/images/14.png" alt="" />
            </div>
            <span>专注</span>
          </div>
          <div class="brand-item">
            <div class="img-wrapper">
              <img src="../assets/images/15.png" alt="" />
            </div>
            <span>奋进</span>
          </div>
          <div class="brand-item">
            <div class="img-wrapper">
              <img src="../assets/images/16.png" alt="" />
            </div>
            <span>求实</span>
          </div>
        </div>
      </div>
    </div>

    <div class="partner-wrapper">
      <Partner class="wrap-v1"></Partner>
    </div>
  </div>
</template>

<script>
import { getNewsList } from "../service/allapi";
import Advantage from "@/components/advantage.vue";
import Partner from "@/components/partner.vue";
import Headline from "@/components/headline.vue";
import Bigimg from "@/components/bigimg.vue";
export default {
  name: "home",
  components: { Advantage, Partner, Headline, Bigimg },
  data() {
    return {
      bannerList: [
        {
          imgUrl: require("../assets/images/banner1.jpg"),
          title: "为世界创造有温度的体验",
          subTitle: "-极致·情感·品牌-"
        },
        {
          imgUrl: require("../assets/images/banner2.jpg"),
          title: "触及视觉灵魂的设计趋势",
          subTitle: "-策略·设计·研发-"
        },
        {
          imgUrl: require("../assets/images/banner3.jpg"),
          title: "持续为用户创造最大价值",
          subTitle: "-专业·积累·匠心-"
        }
      ],
      newsList: []
    };
  },
  methods: {
    goDetails(news) {
      this.$router.push(`/news/details/${news.id}`);
    }
  },
  computed: {
    imgHeight() {
      let windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      return (windowWidth / 1920) * 570 + "px";
    },
    showList() {
      let ans = [];
      ans = [...this.newsList];
      ans.splice(0, 1);
      return ans;
    }
  },
  created() {
    getNewsList({ pageNum: 1, pageSize: 5 }).then(res => {
      this.newsList = res.data.list;
    });
  }
};
</script>
<style lang="scss" scoped>
.mt-7 {
  margin-top: 10px;
}
.home {
  .banner {
    .banner-carousel {
      /deep/.el-carousel__container {
        height: 30vw;
        max-height: 520px;
        min-height: 100px;
      }
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top;
    }
  }
  .service-wrapper {
    background-color: #f7f7f7;
    .service {
      padding-top: 100px;
      padding-bottom: 80px;

      .service-list {
        margin: 65px 20px 0;
        display: flex;
        justify-content: space-between;
        .service-item {
          width: 387px;
          margin-left: 10px;
          margin-right: 10px;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
          .top {
            height: 250px;
            background-color: #007bb9;
            align-content: center;
            font-size: 28px;
            line-height: 40px;
            img {
              padding-top: 50px;
            }
            p {
              padding-top: 25px;
              font-size: 28px;
              line-height: 40px;
              color: #fff;
            }
          }
          .bottom {
            padding: 30px 20px 45px;
            font-size: 16px;
            line-height: 30px;
            color: #6d6d6d;
          }
        }
      }
    }
  }

  .advantage-wrapper {
    background-color: #fff;
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .brand-wrapper {
    background-color: #f7f7f7;
    .brand {
      padding-top: 150px;
      padding-bottom: 110px;

      .brand-list {
        margin-top: 64px;
        color: #fff;
        display: flex;
        justify-content: space-around;
        .brand-item:nth-child(1) {
          background-image: url("../assets/images/9.png");
        }
        .brand-item:nth-child(2) {
          background-image: url("../assets/images/10.png");
        }
        .brand-item:nth-child(3) {
          background-image: url("../assets/images/11.png");
        }
        .brand-item:nth-child(4) {
          background-image: url("../assets/images/12.png");
        }
        .brand-item {
          width: 23%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          .img-wrapper {
            height: 100px;
            margin-top: 115px;
            text-align: center;
            line-height: 100px;
            img {
            }
          }
          span {
            display: block;
            margin-top: 50px;
            font-size: 32px;
            line-height: 45px;
          }
          p {
            padding: 20px;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 123px;
          }
          &:hover {
            background-color: #007bb9;
            background-image: none;
          }
        }
      }
    }
  }

  .news-wrapper {
    background-color: #ffffff;
    .news {
      padding-top: 150px;
      padding-bottom: 85px;
      .news-list-wrapper {
        margin-top: 65px;
        display: flex;
        justify-content: space-around;
        .big-img {
          cursor: pointer;
          width: 50%;
          max-width: 505px;
          height: 510px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .news-list {
          flex: 1;
          margin-left: 40px;
          .news-item {
            display: flex;
            align-items: center;
            .time {
              margin-right: 15px;
              width: 120px;
              height: 120px;
              flex-shrink: 0;
              background: #f7f7f7;
              text-align: center;
              .day {
                font-size: 50px;
                font-weight: 600;
                color: #333333;
                line-height: 70px;
              }
              .month {
                font-size: 22px;
                color: #9c9c9c;
                line-height: 30px;
              }
            }
            .news-desc {
              text-align: left;
              .news-title {
                cursor: pointer;
                font-size: 28px;
                color: #333333;
                line-height: 40px;
              }
              .news-introduction {
                margin-top: 5;
                font-size: 18px;
                color: #9c9c9c;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }

  .partner-wrapper {
    background-color: #fff;
    .partner {
      padding-top: 150px;
      padding-bottom: 110px;
    }
  }

  .title {
    font-size: 50px;
    line-height: 70px;
    color: #333;
  }
  .desc {
    margin-top: 22px;
    font-size: 22px;
    line-height: 30px;
    color: #8b8b8b;
  }
}
</style>
